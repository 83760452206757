import {Component, Input, NgZone, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MailyticaSmartResponse} from 'src/app/services/mailytica-http/MailyticaSmartResponse';
import {Message} from 'src/app/services/mailytica-http/Message';
import {MailyticaHttpService} from 'src/app/services/mailytica-http/mailyticaHttp.service';
import {OfficeRoamingSettingsService} from 'src/app/services/office-roaming-settings/office-roaming-settings.service';
import {SmartResponseService} from 'src/app/services/smartResponse/smart-response.service';
import {ExecuteUserActionData} from 'src/app/services/mailytica-http/ExecuteUserActionData';
import {Metadata} from 'src/app/services/mailytica-http/MetaData';
import {HttpErrorResponse} from '@angular/common/http';
import {MailboxMailyticaAccountMapping} from 'src/app/services/office-roaming-settings/MailboxMailyticaAccountMapping';
import * as constants from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {MetadataModalComponent} from './actions/metadata-modal/metadata-modal.component';
import {OfficeItemChangedService} from 'src/app/services/office-item-changed/office-item-changed.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NotificationService} from '../../services/notification/notification.service';
import {MyUnsubscribeComponent} from '../../my-unsubscribe/my-unsubscribe.component';
import {SmartResponseProposal} from "../../services/mailytica-http/SmartResponseProposal";

@Component({
  selector: 'app-mailytica-SmartResponseGUI',
  templateUrl: './mailytica-SmartResponseGUI.component.html',
  styleUrls: ['./mailytica-SmartResponseGUI.component.css'],
})
export class MailyticaSmartResponseGUIComponent extends MyUnsubscribeComponent implements OnInit, OnChanges {

  @Input() mailyticaAPIUrl: string;
  @Input() mailboxMailyticaAccountMapping: MailboxMailyticaAccountMapping;
  @Input() authorizationValue: string;
  @Input() authorizationValueConfiguredInManifest: boolean;

  public loadingAnimation: boolean = false;
  public mailyticaSmartResponse: MailyticaSmartResponse;
  public currentlyDisplayedSmartResponseProposal: SmartResponseProposal;
  public httpErrorResponse: HttpErrorResponse;
  public loggedIn: boolean;
  public buttonFinishMessageDisabled: boolean = false;
  public constants = constants;

  constructor(
    public mailyticaHttpService: MailyticaHttpService,
    private officeService: OfficeRoamingSettingsService,
    public smartResponseService: SmartResponseService,
    public translate: TranslateService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private zone: NgZone,
    private officeItemChangedService: OfficeItemChangedService,
    private officeRoamingSettingsService: OfficeRoamingSettingsService
  ) {
    super();

    translate.addLangs(['en', 'de', 'es']);
    translate.setDefaultLang('en');
    translate.use(officeService.getLanguage());

    // translate.use(OfficeRoamingSettingsService.getLanguage())
  }

  ngOnInit(): void {

    this.initialize();

    // if(this.authorizationValue != ""){
    //   this.mailyticaHttpService.setAuthorizationValue(this.authorizationValue);
    // }
    // else{
    //   this.mailyticaHttpService.setAuthorizationValue(this.officeRoamingSettingsService.getMailboxAuthorizationValueMapping(this.mailboxMailyticaAccountMapping.mailbox));
    // }
  }

  private async initialize() {
    //this.mailboxMailyticaAccountMapping = await this.mailyticaHttpService.getMailboxMailyticaAccountMapping();
  }

  ngOnChanges(): void {

    this.zone.run(async () => {
      this.httpErrorResponse = undefined;
      if (this.authorizationValue !== '' && this.authorizationValue !== undefined) {
        await this.classifyMessage();
      }
    });
  }

  public async classifyMessage() {

    this.loadingAnimation = true;

    if (this.mailboxMailyticaAccountMapping.categoryModel.categoryModelId || this.authorizationValue !== '') {

      const message = await Message.getMessage();

      const subscription = this.mailyticaHttpService.getSmartResponses(message.fallbackMessagetoJson(), this.mailyticaAPIUrl, this.authorizationValue)
        .pipe(
          catchError(() => this.mailyticaHttpService.getSmartResponses(message.fallbackMessagetoJson(), this.mailyticaAPIUrl, this.authorizationValue)),
          mergeMap((response) => {
            if (JSON.stringify(response) === '{}') {
              return this.mailyticaHttpService.getSmartResponses(message.fallbackMessagetoJson(), this.mailyticaAPIUrl, this.authorizationValue);
            } else {
              return of(response);
            }
          })
        )
        .subscribe((response) => {
          this.updateSmartResponses(MailyticaSmartResponse.fromJson(response));

          this.loadingAnimation = false;
        }, (err: HttpErrorResponse) => {
          this.httpErrorResponse = err;
        });

      this.registerSubscription(subscription);

    } else {
      this.loadingAnimation = false;
    }
  }

  public async updateSelectedTopic(selectedTopic: string) {

    this.loadingAnimation = true;

    const message = await Message.getMessage();

    const subscription = this.smartResponseService
      .updateSelectedTopic(message, selectedTopic, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.updateSmartResponses(mailyticaSmartResponse);

          this.loadingAnimation = false;
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public updateSelectedSmartResponseProposal(selectedSmartResponseProposal: SmartResponseProposal) {
    this.currentlyDisplayedSmartResponseProposal = selectedSmartResponseProposal;
  }

  public updateSmartResponses(mailyticaSmartResponse: MailyticaSmartResponse) {

    this.mailyticaSmartResponse = mailyticaSmartResponse;

    // take first smartResponseProposal with same topic as classification
    this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse
      .smartResponseProposals
      .find(proposal => proposal.topicLabel === this.mailyticaSmartResponse.classification.label);

    // if no smartResponseProposal with same topic as classification was found
    if (this.currentlyDisplayedSmartResponseProposal === undefined) {
      this.currentlyDisplayedSmartResponseProposal = this.mailyticaSmartResponse.smartResponseProposals[0];
    }

    if (this.mailyticaSmartResponse.metadata.find(metadata => metadata.metadataFieldId === 'finished')) {
      this.buttonFinishMessageDisabled = true;
    } else {
      this.buttonFinishMessageDisabled = false;
    }
  }

  public updateMetadata(metadata: Metadata[]) {

    this.mailyticaSmartResponse.metadata = metadata;
  }

  public async checkForMetadataVariables() {

    const metadataVariables: Metadata[] = this.currentlyDisplayedSmartResponseProposal.checkProposalTextForMetadataVariables(this.mailyticaSmartResponse.metadata);

    if (metadataVariables.length > 0) {
      const dialogRef = this.dialog.open(MetadataModalComponent, {
        data: {requiredMetadata: metadataVariables}
      });

      dialogRef.afterClosed().subscribe((newMetadata: Metadata[]) => {
        if (newMetadata) {
          this.consumeSmartResponse(this.currentlyDisplayedSmartResponseProposal.replaceProposalVariables(newMetadata));
        } else {
          this.notificationService.notify('EMAIL-NOT-SENT');
        }
      });
    } else {

      this.consumeSmartResponse(this.currentlyDisplayedSmartResponseProposal.smartResponse);
    }
  }

  public async consumeSmartResponse(smartResponseProposal: string) {

    const replyFormAttachments = this.currentlyDisplayedSmartResponseProposal.attachments.map(attachment => {
      const accessToken = this.authorizationValue.replace('Bearer ', '');
      return {
        type: Office.MailboxEnums.AttachmentType.File,
        name: attachment.filename,
        url: attachment.attachmentApiUrl + accessToken
      };
    });

    Office.context.mailbox.item.displayReplyForm(
      {
        htmlBody: smartResponseProposal,
        attachments: replyFormAttachments
      }
    );

    const subscription = this.smartResponseService
      .consumeSmartResponse(this.mailyticaSmartResponse, this.currentlyDisplayedSmartResponseProposal, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          if (this.mailyticaSmartResponse.clientSettings.finishMessageBySmartResponseStatus === 'active') {

            this.finishMessage();
          }
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  // this is done on server side, could be removed here?
  public async finishMessage() {

    this.loadingAnimation = true;

    const message = await Message.getMessage();

    const subscription = this.smartResponseService
      .finishMessage(message, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.updateSmartResponses(mailyticaSmartResponse);

          this.loadingAnimation = false;

          this.notificationService.notify('FINISH-MESSAGE-RESPONSE');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public async changeSalutation(salutation: string) {

    this.loadingAnimation = true;

    const message = await Message.getMessage();

    const subscription = this.smartResponseService
      .changeSalutation(message, salutation, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.updateSmartResponses(mailyticaSmartResponse);

          this.loadingAnimation = false;

          this.notificationService.notify('SALUTATION-CHANGED');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public async changeLanguage(language: string) {

    this.loadingAnimation = true;

    const message = await Message.getMessage();

    const subscription = this.smartResponseService
      .changeLanguage(message, language, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.updateSmartResponses(mailyticaSmartResponse);

          this.loadingAnimation = false;

          this.notificationService.notify('LANGUAGE-CHANGED');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  public async executeUserAction(executeUserActionData: ExecuteUserActionData) {

    this.loadingAnimation = true;

    const availableFields = new Set(this.mailyticaSmartResponse
      .availableFields
      .filter(field => field.displayInClientStatus !== 'inactive')
      .map(field => {

        if (field.fieldType === 'StringArray') {

          return field.fieldNameId + '_selected';

        } else {


          return field.fieldNameId;
        }
      })
    );

    const metadataFields = this.mailyticaSmartResponse
      .metadata
      .filter(metadata => availableFields.has(metadata.metadataFieldId))
      .filter(metadata => metadata.metadataValue.trim().length > 0);

    const message = await Message.getMessage();
    message.metadataFields = metadataFields;

    const subscription = this.smartResponseService
      .executeUserAction(executeUserActionData, message, this.mailyticaAPIUrl, this.authorizationValue)
      .subscribe(
        (mailyticaSmartResponse) => {

          this.updateSmartResponses(mailyticaSmartResponse);

          this.loadingAnimation = false;

          this.notificationService.notify('USER-ACTION-EXECUTED');
        },
        (error) => console.error(error)
      );

    this.registerSubscription(subscription);
  }

  restart() {
    window.location.reload();
  }
}
